import { getCookie, setCookie, deleteCookie, getCookies } from 'cookies-next'
import { GetServerSidePropsContext } from 'next'

const options = {
    encode: value => value
}

const get = (key?: string, context?: GetServerSidePropsContext): string => {
    return getCookie(key, context ? { req: context.req, res: context.res, ...options } : { ...options }) as string
}

const set = (key: string, value, context?: GetServerSidePropsContext) => {
    setCookie(
        key,
        value,
        context
            ? {
                req: context.req,
                res: context.res,
                ...options,
                domain: (/^.*(\..*\..*$)/.exec(context?.req.headers.host) || [])[1] || context?.req.headers.host
            } : {
                ...options,
                domain: (/^.*(\..*\..*$)/.exec(window.location.hostname) || [])[1] || window.location.hostname || ''
            })
}

const remove = (key: string, context?: GetServerSidePropsContext) => {
    deleteCookie(key, context ? { req: context.req, res: context.res, ...options } : { ...options })
}

export default {
    get,
    set,
    remove,
    getAll: getCookies
}
