import html from 'html'

import { isServer } from '../api/helpers'

export const setElementPosition = (
    element: HTMLElement,
    transform: {
        x: number,
        y: number,
        z: number,
    },
    origin = {
        x: 0,
        y: 0,
        z: 0
    }
): void => {
    if (element) {
        element.style.transform = `translate3d(
        ${transform.x + origin.x}px,
        ${transform.y + origin.y}px,
        ${transform.z + origin.z}px
        )`
    }
}

export const getElementPosition = (element: HTMLElement) => {
    if (!element) return { x: 0, y: 0 }

    const rect: DOMRect = element.getBoundingClientRect()
    const x = rect.x
    const y = rect.y

    return { x, y }
}

export const getElementPositionByOrigin = (element: HTMLElement, origin?: HTMLElement | null) => {
    const originElement = origin || document.body

    const rect: DOMRect = element.getBoundingClientRect()
    const originRect = originElement.getBoundingClientRect()

    const x = rect.x - originRect.x
    const y = rect.y - originRect.y

    return { x, y }
}

export const goTo = (anchor: string) => {
    const element = document.getElementById(anchor)
    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    else console.error(new Error(`anchor "${anchor}" doesn't exist`))
}

export const getBaseUrl = (): string => {
    if (window) {
        const url = window.location
        return url.protocol + '//' + url.host
    }
    return ''
}
export const dataURItoBlob = (dataURI: string): Blob => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString
    if (dataURI.split(',')[0].indexOf('base64') >= 0) { byteString = atob(dataURI.split(',')[1]) } else { byteString = unescape(dataURI.split(',')[1]) }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ia], { type: mimeString })
}

export const getScrollbarWidth = (): number => {
    const outer = document.createElement('div')
    outer.style.visibility = 'hidden'
    outer.style.overflow = 'scroll' // forcing scrollbar to appear
    // outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps
    document.body.appendChild(outer)

    // Creating inner element and placing it in the container
    const inner = document.createElement('div')
    outer.appendChild(inner)

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth)

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer)

    return scrollbarWidth
}

export const getScrollParent = (node: HTMLElement): Node => {
    if (!node || typeof window === 'undefined') return null

    if (node.scrollHeight > node.clientHeight) return node.tagName === 'HTML' ? document : node
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    else return getScrollParent(node.parentNode as HTMLElement)
}

export const formatHtml = (code: string): string => html.prettyPrint(code)

export function getNestedObjectValue<T> (object: T, key: string): typeof object[keyof typeof object] {
    if (object && key) {
        if (key.includes('.')) {
            const data = key.split('.')
            return data.reduce((acc, item) => acc?.[item], object)
        } else return object?.[key]
    }
    return undefined
}

export const bytesToSize = (bytes: number): string => {
    const sizes = ['b', 'kb', 'mb', 'gb', 'tb']
    if (bytes === 0) return 'n/a'
    const i = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`, 10)
    if (i === 0) return `${bytes}${sizes[i]}`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

export const elementsOverlap = (el1: HTMLElement, el2: HTMLElement): boolean => {
    const domRect1 = el1?.getBoundingClientRect()
    const domRect2 = el2?.getBoundingClientRect()

    if (isServer() || !domRect1 || !domRect2) return

    return !(
        domRect1.right < domRect2.left ||
        domRect1.left > domRect2.right
    )
}
