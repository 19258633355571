import styled from '@emotion/styled'

import { zIndex } from 'settings/theme'

import { setSize } from 'utils/style'

export const Element = styled.div<StyleProps>`
  width: ${({ w }) => setSize(w)};
  position: absolute;
  left: 0;
  z-index: ${zIndex.pop};
  &:focus {
    outline: none;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: ${zIndex.pop};
  position: relative;
`
