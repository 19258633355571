import React, { useEffect } from 'react'

import { ConfigProvider } from 'antd'
import en from 'antd/locale/en_US'
import fr from 'antd/locale/fr_FR'
import { AppProps } from 'next/app'
import { useTranslation } from 'react-i18next'

import IconProvider from 'context/icons'
import PromotionsProvider from 'context/promotions'
import UserProvider from 'context/user'

import { DecodedToken, decodeToken, STORAGE_JWT_TOKEN_KEY } from 'utils/auth'
import useRouter from 'utils/hook/useRouter'
import serverCookies from 'utils/server-cookies'
import storage from 'utils/storage'

import { PopProvider } from 'components/ui/pop'

import 'utils/i18n'
import './main.css'

type Props = AppProps & { user: DecodedToken }

const App = ({ Component, pageProps, user }: Props): JSX.Element => {
    const { i18n } = useTranslation()

    const { asPath } = useRouter()
    useEffect(() => {
        storage.set('i18n', i18n.language, 'cookies')
    }, [i18n.language])

    return (
        <IconProvider disabled={asPath.includes('/search')}>
            <UserProvider initialUser={user}>
                <ConfigProvider locale={{ fr, en }[i18n.language]}>
                    <PopProvider>
                        <PromotionsProvider>
                            <Component {...pageProps} />
                        </PromotionsProvider>
                    </PopProvider>
                </ConfigProvider>
            </UserProvider>
        </IconProvider>
    )
}
App.getInitialProps = async appContext => {
    const user = decodeToken(serverCookies.get(STORAGE_JWT_TOKEN_KEY, appContext.ctx))

    return {
        user
    }
}

export default App
