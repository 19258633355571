export const colors = {
    white: '#ffffff',
    black: '#525454',
    grey: '#F3EFEA',
    darkGrey: '#D3D3D3',
    tertiary: '#2683d3',
    secondary: '#FF7800',
    primary: '#23A89A',
    darkPrimary: '#14887b',
    lightPrimary: '#F1F2FA',
    error: '#DE5B49',
    edit: '#91de49'
}

// global theme -------------------------------------

export const global = {
    darkMode: false,
    // main font size
    fs: 14,
    // main border radius
    br: 10,
    // main font-weight
    fw: 500,
    bc: '#ffffff',
    color: colors.black,
    padding: 16,
    shadow: '0 3px 15px #2C170429',
    shadowLight: '0 3px 6px #2C17043F'
}

export const globalDark = {
    ...global,
    darkMode: true,
    bc: '#171919',
    color: colors.white
}

// --------------------------------------------------

//  layout theme  -----------------------------------

export const header = {
    height: 80,
    bc: '#F3EFEA'
}

export const footer = {
    bc: colors.grey
}

export const container = {
    maxWidth: 1636,
    padding: global.padding
}

// --------------------------------------------------

//  form theme  -------------------------------------

export const input = {
    bc: '#ffffff',
    fs: global.fs,
    height: 50,
    br: 50 / 2, // height / 2
    shadow: undefined,
    padding: global.padding,
    borderColor: '#AEA69C',
    borderSize: 1,
    focus: {
        borderColor: colors.primary
    },
    label: {
        fs: global.fs,
        weight: 600
    },
    small: {
        height: 25,
        padding: 9
    }
}

export const button = {
    bc: colors.primary,
    color: colors.white,
    fs: global.fs,
    height: input.height,
    br: input.height / 2
}

// --------------------------------------------------

export const zIndex = {
    tooltip: 10,
    modal: 100,
    header: 3,
    drawer: 1,
    footer: 5,
    pop: 200
}

// ui components theme ------------------------------

export const tooltip = {
    bc: '#000000',
    padding: 10,
    fs: 12,
    color: '#ffffff'
}

export const text = {
    p: {
        fs: global.fs
    },
    small: {
        fs: 12
    }
}

export const link = {
    fs: global.fs,
    color: colors.primary,
    hoverColor: colors.primary,
    fw: global.fw
}

export const modal = {
    wrapper: {
        bc: '#00000070'
    },
    container: {
        bc: '#ffffff',
        br: global.br,
        padding: global.padding
    }
}

// --------------------------------------------------

export default {
    colors,
    global,
    header,
    footer,
    container,
    input,
    button,
    tooltip,
    text,
    link,
    switch: {
        height: 18,
        width: 40
    }
}
