import React from 'react'

import { DecodedToken } from 'utils/auth'

const UserContext = React.createContext(undefined)
export const useUser = () => React.useContext(UserContext)

type UserProvider = {
    initialUser: DecodedToken,
    children: Children
}

const UserProvider = ({ initialUser, children }: UserProvider): JSX.Element => {
    return (
        <UserContext.Provider value={initialUser}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider
