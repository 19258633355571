import React from 'react'

import loadable from '@loadable/component'

import { getIcons } from 'utils/get-icons'

import Loader from 'components/loader'

import { Wrapper } from './styles'
const IconsContext = React.createContext(undefined)
export const useIcons = () => React.useContext(IconsContext)

type Props = {
    children: React.ReactNode
    disabled?: boolean
}

const IconsProvider = ({ children, disabled }: Props): JSX.Element => {
    const MdIconSet = loadable.lib(() => getIcons('md'))
    const IoIconSet = loadable.lib(() => getIcons('io'))

    if (disabled) return <>{children}</>
    const loadingWrapper = <Wrapper><Loader size="100%" /></Wrapper>
    return (
        <MdIconSet>
            {({ default: md }) => (
                <IoIconSet fallback={loadingWrapper}>
                    {({ default: io }) => (
                        <IconsContext.Provider value={{ ...md, ...io }}>
                            {children}
                        </IconsContext.Provider>
                    )}
                </IoIconSet>
            )}
        </MdIconSet>
    )
}

export default IconsProvider
