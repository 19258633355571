import React, { useContext, useEffect, useRef, useState } from 'react'

import { Portal } from 'react-portal'
import { useEventListener } from 'usehooks-ts'

import { zIndex } from 'settings/theme'

import { isServer } from 'utils/api/helpers'
import { getElementPosition, getScrollParent } from 'utils/dom'
import { setStyle } from 'utils/style'

import { Element } from './styles'

type PopProps = {
    action?: 'click' | 'hover',
    visible?: boolean,
    children: Children,
    target: React.MutableRefObject<HTMLElement>,
    onClose?: () => void,
    align: 'left' | 'right' | 'center',
    w?: number | string,
}

const Wrapper = ({ children, action, align, target, visible }): JSX.Element => {
    const handleAction = e => {
        const { current } = children.ref
        const target = e.target

        if (!current.contains(target)) {
            if (action) action()
        }
    }

    useEffect(() => {
        const { current } = children.ref

        if (current) {
            current.parentNode.setAttribute('data-pop', 'true')
            setStyle({ current: current.parentNode }, [
                { property: 'position', value: 'absolute' },
                { property: 'left', value: 0 },
                { property: 'top', value: 0 },
                { property: 'zIndex', value: zIndex.pop }
            ])
        }
    }, [])

    const setElementPosition = () => {
        const { current } = children.ref

        if (target.current && current && visible) {
            const { x, y } = getElementPosition(target.current)

            setStyle({ current }, [
                { property: 'opacity', value: 0 }
            ])

            const offset = {
                y: target.current.offsetHeight + 5 + window.scrollY,
                x: current.offsetWidth / 2
            }

            if (align === 'left') offset.x = 0
            else if (align === 'right') offset.x = current.offsetWidth - target.current.offsetWidth

            setStyle({ current }, [
                { property: 'top', value: y + offset.y + 'px' },
                { property: 'left', value: x - offset.x + 'px' }
            ])
            current.focus()

            setStyle({ current }, [
                { property: 'opacity', value: 1 },
                { property: 'transition', value: 'opacity 0ms 20ms' }
            ])
        }
    }
    const scrollContent = getScrollParent(target?.current)

    /*
        const setElementFixedToTarget = () => {
        requestAnimationFrame(() => {
            if (!rect) return
            const currentRect = target?.current?.getBoundingClientRect()
            const { current } = children.ref
            const y = rect.y - currentRect.y
            current.style.transform = `translateY(${-y}px)`
        })
    }
     */

    const closeElement = () => action()

    useEffect(() => {
        setElementPosition()
    }, [children.ref, visible])

    useEffect(() => {
        if (target.current && visible) {
            new ResizeObserver(() => setElementPosition()).observe(target.current)
        }
    }, [target])

    useEventListener('click', handleAction)
    useEventListener('resize', () => setElementPosition())

    if (scrollContent) {
        scrollContent.addEventListener('scroll', closeElement, false)
    } else {
        document.addEventListener('scroll', closeElement, false)
    }

    useEffect(() => {
        return () => {
            if (scrollContent) {
                scrollContent.removeEventListener('scroll', closeElement, false)
            } else {
                document.addEventListener('scroll', closeElement, false)
            }
        }
    }, [])

    useEventListener('scroll', closeElement)

    return <Portal>{children}</Portal>
}

const PopContext = React.createContext(undefined)
export const usePop = () => useContext(PopContext)

export const PopProvider = ({ children }): JSX.Element => {
    const [closePop, setCloseAction] = useState()

    return <PopContext.Provider value={{ closePop, setCloseAction }}>{children}</PopContext.Provider>
}

const Pop = ({
    action,
    visible,
    children,
    target,
    align,
    onClose,
    ...props
}: PopProps): JSX.Element => {
    const elementRef = useRef<HTMLDivElement>(null)

    const { setCloseAction } = usePop()

    useEffect(() => {
        if (onClose && visible) setCloseAction(() => onClose)
    }, [onClose, visible])

    const onKeyup = (e) => {
        if (e.key === 'Escape') onClose()
    }

    useEventListener('keyup', onKeyup)

    if (!visible || isServer()) return null

    return (
        <Wrapper action={onClose} align={align} target={target} visible={visible}>
            <Element
                onMouseLeave={() => action === 'hover' && onClose()}
                {...props}
                ref={elementRef}
            >
                {children}
            </Element>
        </Wrapper>
    )
}

export default Pop
