import Cookie from 'js-cookie'

type TypeProp = 'localStorage' | 'cookies'

const getStorage = (type: TypeProp) => {
    const storage = {
        get: (key: string) => null,
        set: (key: string, value: string) => null,
        remove: (key: string) => null
    }

    if (type === 'localStorage') {
        storage.get = localStorage.getItem
        storage.set = localStorage.setItem
        storage.remove = localStorage.removeItem
    }

    if (type === 'cookies') {
        storage.get = Cookie.get
        storage.set = Cookie.set
        storage.remove = Cookie.remove
    }

    return storage
}

const get = (key: string, type: TypeProp) : string | null => {
    const storage = getStorage(type)

    try {
        const value = storage.get(key)

        if (typeof value === 'string') {
            return value || null
        }
        return value ? JSON.parse(value) : null
    } catch (e) {
        console.error(
            `Unexpected error thrown when GET ${key} in local storage\n`,
            e
        )
        return null
    }
}

const set = (key: string, value: string, type: TypeProp): void => {
    const storage = getStorage(type)

    try {
        if (typeof value === 'string') {
            storage.set(key, value)
        } else {
            const stringyfiedValue = JSON.stringify(value)
            storage.set(key, stringyfiedValue)
        }
    } catch (e) {
        console.error(
            `Unexpected error thrown when SET {${key}, ${value}} in local storage\n`,
            e
        )
    }
}

const remove = (key: string, type: TypeProp): void => {
    const storage = getStorage(type)

    try {
        storage.remove(key)
    } catch (e) {
        console.error(
            `Unexpected error thrown when REMOVE ${key} in local storage\n`,
            e
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    get,
    set,
    remove
}
