import camelcase from 'camelcase'
import { TransitionStatus } from 'react-transition-group'

export const setSize = (size: string | number): string => typeof size === 'number' ? `${size}px` : size

export const setCentered = (center: boolean): string => center ? 'align-items: center;justify-content: center;' : null

export const setBreakpoint = (bp: number | string, rules: string): string => bp ? `@media (max-width: ${setSize(bp)}){${rules}}` : null

export const LightenDarkenColor = (col: string, amt: number): string => {
    const num = parseInt(col, 16)
    const r = (num >> 16) + amt
    const b = ((num >> 8) & 0x00FF) + amt
    const g = (num & 0x0000FF) + amt
    const newColor = g | (b << 8) | (r << 16)
    return '#' + newColor.toString(16)
}

export const setStyle = (
    ref: { current: HTMLElement },
    rules : {
        property: keyof CSSStyleDeclaration,
        value: string | number
    }[]
): void => {
    if (ref?.current) {
        rules.forEach(({ property, value }) => {
            ref.current.style[camelcase(property as string)] = value
        })
    }
}

export function setTransition<T> (
    state: TransitionStatus,
    { active, disabled }: { active: T; disabled: T }
): T {
    switch (state) {
    case 'entering':
        return disabled
    case 'entered':
        return active
    case 'exiting':
        return active
    case 'exited':
        return disabled
    default:
        return disabled
    }
}
