import { STORAGE_LOCALE_KEY } from 'utils/i18n'

export const isServer = (): boolean => typeof window === 'undefined'

export const extractContextMessage = (message: string): ContextMessage => {
    try {
        return JSON.parse(message)
    } catch (exception) {
        return null
    }
}

export const getViolations = (error: Error): Violations => {
    const { response } = error
    return response?.data?.violations
}

export const getLocalFromApiOptions = (options): string | null => options?.context?.req.headers.cookie?.match(new RegExp('(^| )' + STORAGE_LOCALE_KEY + '=([^;]+)'))?.[2] || null
