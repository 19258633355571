import additionalEN from '../../public/locales/en/additional.json'
import translationEN from '../../public/locales/en/translations.json'
import wordsEN from '../../public/locales/en/words.json'
import additionalFR from '../../public/locales/fr/additional.json'
import translationFR from '../../public/locales/fr/translations.json'
import wordsFR from '../../public/locales/fr/words.json'

const resources = {
    en: {
        translations: translationEN,
        words: wordsEN,
        additional: additionalEN
    },
    fr: {
        translations: translationFR,
        words: wordsFR,
        additional: additionalFR
    }
}

export default resources
