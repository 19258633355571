import jwtDecode from 'jwt-decode'
import { GetServerSidePropsContext } from 'next'
import ServerCookie from 'next-cookies'

import storage from 'utils/storage'
import { User } from 'utils/user'

export interface DecodedToken extends User {
    readonly email: string
    readonly exp: number,
    readonly roles: string[]
}

export const STORAGE_JWT_TOKEN_KEY = 'jwt-token'

export const removeToken = (): void => storage.remove(STORAGE_JWT_TOKEN_KEY, 'cookies')
export const getToken = (): string => storage.get(STORAGE_JWT_TOKEN_KEY, 'cookies')
export const getTokenFromServer = (props: unknown): string => ServerCookie(props)[STORAGE_JWT_TOKEN_KEY]

export const decodeToken = (token: string): DecodedToken | undefined => token ? jwtDecode(token) : undefined
export const expiresAt = (token: string): Date => new Date(decodeToken(token).exp * 1000)
export const isExpired = (token: string): boolean => new Date() > expiresAt(token)
export const isValid = (token: string): boolean => decodeToken(token) && !isExpired(token)
export const getTokenFromContext = (context: GetServerSidePropsContext): string => context ? context.req.cookies[STORAGE_JWT_TOKEN_KEY] : null
