import { NextRouter, useRouter as useRouterNext } from 'next/router'

import routes from 'settings/routes.json'

type Key = {
    value: string | string[]
    key: string
}

type UpdateParams = (keys: Key[], callback?) => void

type Router = {
    routes: typeof routes
    updateParams: UpdateParams
    pageProps
} & NextRouter

const useRouter = (): Router => {
    const router = useRouterNext() as NextRouter & {components}

    const updateParams = (keys: ({ key: string, value: string })[], callback): void => {
        keys.forEach(({ key, value }) => {
            router.query[key] = value
        })

        Object.keys(router.query).forEach(key => {
            if (router.query[key] === undefined || router.query[key] === '') {
                delete router.query[key]
            }
        })

        router
            .replace(
                {
                    pathname: router.pathname,
                    query: router.query
                },
                null,
                { scroll: false }
            )
            .then(() => callback && callback())
    }
    const pageProps = router.components?.[router.route].props.pageProps
    return { ...router, routes, updateParams, pageProps }
}

export default useRouter
