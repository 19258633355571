import React from 'react'

import { TailSpin } from 'react-loader-spinner'

import { colors } from 'settings/theme'

type LoaderProps = {
    size?: number | string
}

const Loader = ({ size }: LoaderProps): JSX.Element => (
    <TailSpin ariaLabel="loading-indicator" color={colors.primary} width={size} />
)

export default Loader
