import React, { useContext, useState } from 'react'

const PromotionsContext = React.createContext(undefined)
export const usePromotions = () => useContext(PromotionsContext)

const PromotionsProvider = ({ children }): JSX.Element => {
    const [promotions, setPromotions] = useState([])

    const addPromotion = (element) => {
        setPromotions([element, ...promotions.filter(({ training }) => training !== element.training)])
    }

    const removePromotion = (element) => setPromotions([...promotions.filter(({ training }) => training !== element.training), { ...element, deleted: true }])

    return (
        <PromotionsContext.Provider value={{ promotions, addPromotion, removePromotion }}>
            {children}
        </PromotionsContext.Provider>
    )
}

export default PromotionsProvider
